<template>
    <defaultSec>
        <template #title>
            <div  class="default-header" style="display: flex;justify-content: space-between">
                <h3>工单管理</h3>
                <div>
                        <el-button style="float: right; padding: 5px 10px" type="danger" @click="toInstructions" icon="el-icon-s-claim">工单管理指导手册</el-button>
                </div>

            </div>

        </template>
        <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
            <el-form-item label="购买渠道">
                <el-radio-group size="mini" v-model="formParam.orderOnline">
                    <el-radio-button :label="null">全部</el-radio-button>
                    <el-radio-button :label="1">线上</el-radio-button>
                    <el-radio-button :label="2">线下</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="售后类型">
              <el-select size="small" style="width: 100%" clearable v-model="formParam.workOrderTypeCode" placeholder="请选择">
                <el-option
                    v-for="item in tabList"
                    :key="item.workOrderTypeCode"
                    :label="item.workOrderTypeName"
                    :value="item.workOrderTypeCode">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="外部单号">
                <el-input v-model="formParam.someID" placeholder="请填写" clearable></el-input>
            </el-form-item>
            <el-form-item label="工单状态">
                <el-select size="small" style="width: 100%" clearable v-model="formParam.workOrderStatus" placeholder="请选择">
                    <el-option
                        v-for="item in statusList"
                        :key="item.key"
                        :label="item.name"
                        :value="item.key">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="创建人">
                <el-select style="width: 100%" filterable v-model="formParam.createUserId" placeholder="请选择" clearable>
                    <el-option
                        v-for="item in userList"
                        :key="item.USER_ID"
                        :label="item.USER_NAME"
                        :value="item.USER_ID">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="当前处理人">
                <el-select style="width: 100%" filterable v-model="formParam.handleUserId" placeholder="请选择" clearable>
                    <el-option
                        v-for="item in userList"
                        :key="item.USER_ID"
                        :label="item.USER_NAME"
                        :value="item.USER_ID">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="责任部门">
                <el-select filterable style="width: 100%" v-model="formParam.dingDeptId" placeholder="请选择">
                    <el-option
                        v-for="item in deptList"
                        :key="item.key"
                        :label="item.name"
                        :value="item.key">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间">
                <el-date-picker
                    clearable
                    v-model="formParam.createTimeStr"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="initList">查询</el-button>
                <el-button type="warning" @click="toDetail(0)">新建工单</el-button>
            </el-form-item>
        </el-form>
        <tablePagination :total="total" :pageSize="formParam.pageSize" :currentPage="formParam.pageNum" @handlePage="handlePage">
            <el-table v-loading="loading" :data="tableList" border size="mini">
                <el-table-column type="index" label="序号"></el-table-column>
<!--                <el-table-column prop="workOrderNo" label="工单编号"></el-table-column>-->
                <el-table-column prop="orderOnline" label="购买渠道" width="80">
                  <template slot-scope="scope">
                    <i style="color: #1593eb;font-size: 14px" :class="scope.row.orderOnline=='线上'?'el-icon-goods':'el-icon-s-shop'"></i>
                    {{scope.row.orderOnline}}
                  </template>
                </el-table-column>
                <el-table-column prop="workOrderTypeName" label="售后问题" minWidth="220">
                    <template slot-scope="scope">
                        <div class="label-text"><b>售后类型：</b><span>{{scope.row.workOrderTypeName}}</span></div>
                        <div class="label-text"><b>问题描述：</b><span class="copy-text">{{scope.row.workOrderDesc}}</span></div>
                    </template>
                </el-table-column>
                <el-table-column prop="orderNo" label="订单信息" min-width="220">
                    <template slot-scope="scope">
                        <div class="label-text" v-if="scope.row.orderNo"><b>订单编号：</b><span>{{scope.row.orderNo}}</span></div>
                        <div class="label-text" v-if="scope.row.refundNo"><b>售后单号：</b><span>{{scope.row.refundNo}}</span></div>
                        <div class="label-text" v-if="scope.row.offlineOrderNo"><b>线下单号：</b><span>{{scope.row.offlineOrderNo}}</span></div>
                        <div class="label-text" style="display: block" v-if="scope.row.productList&&scope.row.productList.length>0"><b>售后商品：</b>
                            <span class="copy-text">
                                <span style="display:block;font-weight: normal;margin-bottom: 5px" v-for="item in scope.row.productList">
                                    <i style="color:#dfb81d">● </i>
                                    {{item.productName}}{{item.productPici?'（'+item.productPici+'）':''}}
                                </span>
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="工单状态" width="80">
                    <template slot-scope="scope">
                        <span :style="{color: scope.row.workOrderStatus==0?'#999999':(scope.row.workOrderStatus==1?'#dfb81d':(scope.row.workOrderStatus==10?'green':'#e85656'))}">● {{scope.row.workOrderStatusName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="workOrderResult" label="工单处理" min-width="220">
                    <template slot-scope="scope">
                        <div class="label-text"><b>处理人员：</b><span>{{scope.row.handleUserId}}</span></div>
                        <div class="label-text" v-if="scope.row.responsibleDeptDingdingId"><b>责任部门：</b><span><el-tag type="success" size="mini">{{scope.row.responsibleDeptDingdingId}}</el-tag></span></div>
                        <div class="label-text" v-if="scope.row.workOrderResult"><b>处理结果：</b><span>{{scope.row.workOrderResult}}</span></div>
                        <div class="label-text" v-if="scope.row.workOrderResultDesc"><b>结果描述：</b><span class="copy-text">{{scope.row.workOrderResultDesc}}</span></div>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建信息">
                    <template slot-scope="scope">
                        <p>{{scope.row.createUserId}}</p>
                        <p>{{scope.row.createTime}}</p>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="160">
                    <template slot-scope="scope">
                        <el-button style="margin-right: 8px" type="danger" plain size="mini" @click="toDetail(scope.row.workOrderNo)">查看</el-button>
                        <el-popconfirm
                            v-if="scope.row.workOrderStatus!=0&&scope.row.workOrderStatus!=10"
                            title="确定要作废这条工单吗？"
                            @confirm="delWorkOrder(scope.row.workOrderNo)"
                        >
                            <el-button slot="reference" type="warning" plain size="mini">作废</el-button>

                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </tablePagination>
    </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
import tablePagination from '@c/tablePagination'
export default {
    name: "workOrderList",/*此处的name必须有且与include中的一致*/
    data() {
        return {
            formParam:{
                pageSize:10,
                pageNum:this.$route.params.pageNum||1,
            },
            tableList:[],
            userList:[],
            deptList:[],
            tabList:[],
            statusList:[],
            total:0,
            formData:{},
            addWorkOrderVisible:false,
            loading:true
        }
    },
    components:{
        defaultSec,tablePagination
    },
    mounted(){
        this.initWorkTypeList()
        this.initUserList()
        this.initStatusList()
        this.initDeptList()
        this.initList()
    },
    watch:{
        $route(to,from){
            console.log(to,from)
            // window.location.reload()
        }
    },
    activated(){
    },
    methods: {
        initList() {
            this.loading = true
            let param = {...this.formParam};
            param.createTimeStr = param.createTimeStr?param.createTimeStr.join(','):null;
            this.$apiGet('lechun-csms/workOrder/workOrderList',param).then(res => {
                console.log(res)
                this.loading = false
                this.total = res.total;
                this.tableList = res.list

            })
        },
        initUserList() {
            this.$apiGet('lechun-baseservice/user/getUserListForDorpbox',{}).then(res => {
                console.log(res)
                this.userList = res

            })
        },
        initDeptList() {
            this.$apiGet('lechun-csms/workOrder/getWorkOrderResponseDept',{}).then(res => {
                console.log(res)
                this.deptList = res

            })
        },
        initStatusList() {
            this.$apiGet('lechun-csms/workOrder/workOrderStatusSimple',{}).then(res => {
                console.log(res)
                this.statusList = res

            })
        },
        initWorkTypeList() {
            this.$apiGet('lechun-csms/workOrder/workOrderTypeList',{}).then(res => {
                console.log(res)
                this.tabList = res

            })
        },
        delWorkOrder(id) {
            this.$apiGet('lechun-csms/workOrder/deleteWorkOrder',{ workOrderNo:id}).then(res => {
                console.log(res)
                this.$message({
                    message: '工单已作废',
                    type: 'success'
                })
                this.initList()

            })
        },
        handlePage(page){
            this.formParam.pageNum = page;
            this.$router.push({
                name: 'workOrderList',
                params: {pageNum: page}
            })
            this.initList()

        },
        toDetail(id){
            this.$router.push({
                name: 'workOrderDetail',
                params: {id: id},
                query: {
                    editAble: 1
                }
            })
        },
        toInstructions(){
            window.open('https://alidocs.dingtalk.com/i/nodes/ydxXB52LJq7YOprLFqxjLnBGWqjMp697?cid=4448346107&corpId=ding5473140ac462716435c2f4657eb6378f&doc_type=wiki_doc&iframeQuery=utm_medium=im_card&utm_source=im&utm_medium=im_card&utm_scene=person_space&utm_source=im','_blank')
        },
    }
}
</script>


<style scoped lang="scss">
.label-text{
    display: flex;
    margin-bottom: 5px;
    b{
        width: 60px;
        display: block;
        text-align: right;
        font-weight: normal;
    }
    >span{
        flex: 1;
        font-weight: bolder;
    }
}
.copy-text {
    display: block;
    white-space: pre-line;
    margin: 0;
    line-height: 18px;
    background: #f2f2f2;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 6px;
    max-height: 127px;
    overflow-y: scroll;
}
</style>